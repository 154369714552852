.modal-backdrop {
  &.confirm-backdrop {
    z-index: calc(var(--bs-backdrop-zindex) + 6);
  }
}

.modal {
  &.confirm {
    z-index: calc(var(--bs-modal-zindex) + 1);
  }
}