@import "../_base/base.scss";

.Research {
  @include page-base;

  padding-bottom: 3rem;

  &.multi-chart-mode {
    padding-bottom: 3.7rem;
  }

  .config {
    >.dropdown-toggle {
      color: var(--bs-nav-link-color);

      &:disabled {
        color: var(--bs-btn-disabled-color);
      }

      &::after {
        display: none;
      }
    }

    &-product-price {
      >.dropdown-toggle {
        margin-right: calc(-1 * var(--bs-btn-padding-x));
      }
    }
  }

  .wm-20 {
    max-width: 20rem;
  }
}
