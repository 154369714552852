.TransRouter {
  &__RootMain {
    position: relative;
    width: 100%;
    min-height: 100%;
  }

  &__MotionSection {
    position: absolute;
    width: 100%;
    min-height: 100%;
  }

  &__OverflowHidden {
    overflow: hidden;
  }
}