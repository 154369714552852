.Item {
  position: relative;

  &._active {
    &::after {
      position: absolute;
      width: 100%;
      height: 3px;
      background-color: rgba(99, 99, 99, 0.59);
      content: '';
    }

    &._before::after {
      top: -1px;
    }

    &._after::after {
      bottom: -1px;
    }
  }
}