@import "~bootstrap/scss/bootstrap";
@import "./components/trans-router/trans-router.scss";

html,
body,
#root {
  height: 100%;
}

.btn-icon {
  width: 32px;
}

.aaaa {
  transform: none !important;
}